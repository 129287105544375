<template>
    <div style="max-width: 1600px; margin: auto; position: relative;">
        <v-row class="mt-1 mb-2 d-flex align-start justify-space-between">
            <v-col cols="12">
                <h4 class="titlePages text-left font-weight-medium mb-4">Configurações
                </h4>
                <p style="max-width: 60%;" class="mb-0 text-body-2 font-weight-light text-left mt-2">
                    Configure bancos para geração de boletos, crie perfis para diferentes padronizações,
                    envie, gere
                    PDFs e emita novos boletos para acelerar e facilitar suas cobranças.
                </p>
            </v-col>
            <v-col cols="12" class="d-flex justify-start">
                <CardBank />
                <CardProfile class="ml-10" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import CardBank from "./CardBank.vue"
import CardProfile from "./CardProfile.vue"
export default {
    components: {
        CardBank,
        CardProfile,
    },
}
</script>

<style lang="scss" scoped></style>